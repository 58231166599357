import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    units: [], 
    loading: false,
};

const unitsSlice = createSlice({
    name: 'units',
    initialState,
    reducers: {
        setUnits: (state, action) => {
            state.units = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
});

export const { setUnits, setLoading } = unitsSlice.actions;
export default unitsSlice.reducer;
