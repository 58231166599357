import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    discounts: [], // Your discounts list
    loading: false,
};

const discountsSlice = createSlice({
    name: 'discounts',
    initialState,
    reducers: {
        setDiscounts: (state, action) => {
            state.discounts = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
});

export const { setDiscounts, setLoading } = discountsSlice.actions;
export default discountsSlice.reducer;
