import { configureStore } from '@reduxjs/toolkit';
import headerSlice from '../features/common/headerSlice';
import modalSlice from '../features/common/modalSlice';
import rightDrawerSlice from '../features/common/rightDrawerSlice';
import brandsReducer from '../features/brands/brandsSlice';
import unitsReducer from '../features/units/unitsSlice';
import discountsReducer from '../features/discounts/discountsSlice';

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  brands: brandsReducer,
  units: unitsReducer, 
  discounts: discountsReducer
};

export default configureStore({
  reducer: combinedReducer,
});
